@use "sass:math";

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #eee;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #bbb;
$gray-600: #666;
$gray-700: #444;
$gray-800: #222;
$gray-900: #212121;
$black: #000;
$blue: #2196f3;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e51c23;
$orange: #fd7e14;
$yellow: #ff9800;
$green: #4caf50;
$teal: #20c997;
$cyan: #9c27b0;
$primary: $blue;
$secondary: $white;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $gray-800;

$max-width-container: 1600px;
$min-height-container: 100vh;
/* taille de texte fluide */
$min_width: 320px;
$max_width: 1600px;
$min_font: 12px;
$max_font: 18px;
/**/
/* couleur de fond du body*/
$couleurBodyBg: #345e1a;

/* fond de page */
$dynBackground-color: white;
/* texte */
$dynColor: #000;
/*lien*/
$a: #5aa02f;
/* liens survolés*/
$ahover: #f56818;
/* COuleur des H1,H2 ...*/
$Hcolor: #f56818;

/*NAVBAR*/
$navBar_a: $white;
$navBar_aActive: whitesmoke;
$navBar_aHover: whitesmoke;
$navBar_bg: #5aa02f;
$navBar_color: black;
/*recherche + panier*/
$couleurRechBg:#ffffffbb;
$couleurRech:#333;
$couleurRechMob:white;
$couleurPanierBtn:#000;
$couleurPanierBtnMob:white;
/*Modules sur page HOME*/
$couleurModulesBg: $dynBackground-color;
$HcolorModules: #8b2005;
$btnProduitColor: white;
$btnProduitBgColor: #5aa02f;
$produitBgColor: white;
$hoverProduitBgColor: whitesmoke;
$borderColor: #5aa02f;
/*Catalogue*/
/*Modules sur catalogue*/
$couleurModulesCatBg: #5aa02f55;
$couleurModulesCatBlockBg: white;
$couleurModulesCatFont: #8b2005;
$aModulesCat: #333;

/*scrollbars*/
$scrollBarElement: $gray-900;
$scroolBarFd: $gray-400;
/*produits*/
$colorPrix: #f56818;
$bgCategorie:#5aa02f77;
$coloraCategorie:#333;
$bgCarousel:$dynBackground-color;
$bgResume:#5aa02f55;
$bgDescription:#5aa02f22;
$bgPrixAction:#5aa02f44;
$snackBG:#5aa02f;
$snackCol:#000;
$snackColBtn:white;
/* hauteur du contenu*/
$min-H-container: calc(100vh - 64px);
$min-H-dyn: calc(100vh - 64px - 30px);
@mixin setLinkStyleAll {
  &,
  &:active,
  &:link,
  &:visited {
    @content;
  }
}
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1==$u2 and $u1==$u3 and $u1==$u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
@mixin truncate($width: 300px) {
  display: inline-flex;

  > * {
    display: inline-block;
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  // ie6
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
@mixin hideScrollbar {
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}