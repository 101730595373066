@use "sass:math";

@use '~@angular/material' as mat;
@import url("https://fonts.googleapis.com/css?family=Roboto");
/* @import '~material-design-icons/iconfont/material-icons.css'; */
@import "variables";


@include mat.core();
$d4-app-primary: mat.define-palette(mat.$green-palette);
$d4-app-accent: mat.define-palette(mat.$deep-orange-palette);
$d4-app-warn: mat.define-palette(mat.$red-palette);
$d4-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $d4-app-primary,
      accent: $d4-app-accent,
      warn: $d4-app-warn,
    ),
  )
);
@include mat.all-component-themes($d4-app-theme);
.dyn {
  a {
    @include setLinkStyleAll {
      color: $a;
      text-decoration: none;
    }

    &:hover {
      color: $ahover;
    }
  }
  img {
    max-width: calc(100% - 10px) !important;
    height: auto !important;
  }
}
#container {
  background-color: $couleurBodyBg;
  
}
html,
body {
  height: 100%;@include scrollbars(10px,$scrollBarElement,$scroolBarFd);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.spacer {
  flex: 1 1 auto;
}
#fidelite {
  padding: 10px 20px;
  width: 250px;
  border: 1px solid $borderColor;
  border-radius: 5px;
  .fidelheader {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    color: $Hcolor;
    margin: 0.3em;
  }
  .fidelcontent {
    width: 250px;
    span {
      width: 50px;
      height: 50px;
      &.off{background-image: url("/images/fidelite0.png");}
      &.on{background-image: url("/images/fidelite1.png");}
      
    }
  }
  .fidelresult {
    color: $Hcolor;
    margin: 0.3em;
    text-align: center;
    font-size: 0.8em;
  }
}
//utilities
.clearfix {
  @include clearfix();
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9 {
  &::before {
    padding-top: percentage(math.div(9, 21));
  }
}

.embed-responsive-16by9 {
  &::before {
    padding-top: percentage(math.div(9, 16));
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: percentage(3 * 0.25);
  }
}

.embed-responsive-1by1 {
  &::before {
    padding-top: percentage(math.div(1, 1));
  }
}
.bg-white {
  background-color: whitesmoke;
}
.SnackBarAdddPanier{
  background: $snackBG !important;
  color: $snackCol;
  button{
    color:$snackColBtn;
  }
}

